import Head from 'next/head'

import {
  useStoryblokState,
  getStoryblokApi,
  StoryblokComponent,
  ISbStoryData,
} from '@storyblok/react'
import {GetStaticPathsResult, GetStaticPropsContext} from 'next'
import Layout from '../components/Layout/Layout'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'

import {
  AlertStoryblok,
  FooterStoryblok,
  HeaderStoryblok,
  MainMenuStoryblok,
  RedirectEntryStoryblok,
  ServicePointDetailPageStoryblok,
} from 'types/storyblok-types'
import {refreshTranslations} from 'util/translations'
import Script from 'next/script'
import {SSM} from 'lib/SSM'
import {constructSBParams} from 'util/StoryblokHelper'
import {Constants} from 'util/constants'
import {ServiceDeliveryPointType} from 'types/api-types'

import {CachedServiceDeliveryPoint, TranslatedSlug} from 'types/types'
import {SDPHelper} from 'util/SDPHelper'
import ServicePointDetailPage from 'components/Nodes/ServicePointDetailPage/ServicePointDetailPage'
import findAndReplaceString from 'util/findAndReplaceString'
import sdpGenerator from 'util/slugHelpers/sdpGenerator'
import {singletonsSlug} from 'util/slugHelpers/singletonsSlugs'
import {getLocalAlert} from 'util/slugHelpers/getLocalAlert'
import {getGlobalAlert} from 'util/slugHelpers/getGlobalAlert'
import {findStoryInStoryBlok} from 'util/findStoryInStoryblok'
import {Segment, Tag} from 'util/slugHelpers/segment'
import {useRouter} from 'next/router'
import {fetchSDPsByProvince} from 'util/fetchSDPsByProvince'
import {generateSDPPath} from 'util/slugHelpers/generateSDPPath'
import {UserType, useSessionContext} from 'context/SessionContextProvider'
import linkTypeChecker from 'util/linkTypeChecker'

export type pageTypes = {
  isSDPDetailPage: boolean
  story: ISbStoryData | null
  sdpl?: CachedServiceDeliveryPoint
  global_alert?: AlertStoryblok
  local_alert?: AlertStoryblok
  header: {individual: HeaderStoryblok; professional: HeaderStoryblok}
  locale: string
  footer: {individual: FooterStoryblok; professional: FooterStoryblok}
  mainMenu: {private: MainMenuStoryblok; professional: MainMenuStoryblok}
}

type pathTypes = {
  locales: string[]
}

export default function Page({
  isSDPDetailPage,
  story,
  sdpl,
  locale,
  mainMenu,
  global_alert,
  local_alert,
  header,
  footer,
}: pageTypes) {
  story = useStoryblokState(story, {language: locale})
  const selectedUserType = useSessionContext().selectedUserType
  const router = useRouter()

  if (!story) {
    return <div>Story was null</div>
  }
  let noIndex

  if (router.asPath.includes('contact?search')) {
    noIndex = true
  } else {
    noIndex = false
  }

  let title
  let description
  let ogTitle
  let ogDescription
  let ogImage

  //generate correct metatags for website
  if (isSDPDetailPage) {
    const storyContent = story.content as ServicePointDetailPageStoryblok

    const replaceWithSdplData = (text: string | undefined) => {
      if (!text) {
        return ''
      }
      const valuesToReplace = {
        city: sdpl?.detail_data?.city!,
        name: sdpl?.overview_data.fuelStation?.name!,
        address: sdpl?.detail_data?.address!,
        street: sdpl?.detail_data?.street!,
      }
      return text.replace(/\{(\w+)\}/g, function (_, key) {
        return valuesToReplace[key as keyof typeof valuesToReplace] || ''
      })
    }
    if (sdpl?.detail_data?.type === ServiceDeliveryPointType.fuel) {
      title = replaceWithSdplData(storyContent.fueling_title)
      description = replaceWithSdplData(storyContent.fueling_description)
      ogTitle = title
      ogDescription = description
    }
    if (sdpl?.detail_data?.type === ServiceDeliveryPointType.charge) {
      title = replaceWithSdplData(storyContent.charging_title)
      description = replaceWithSdplData(storyContent.charging_description)
      ogTitle = title
      ogDescription = description
    }
    ogImage = storyContent.generic_delivery_point_image.filename
  } else {
    title = story.content.metatags?.title
      ? `${story.content.metatags.title} | DATS 24`
      : `DATS 24`
    description = story.content.metatags?.description ?? ''
    ogTitle = story.content.metatags?.og_title
      ? story.content.metatags.og_title
      : title
    ogDescription = story.content.metatags?.og_description
      ? story.content.metatags.og_description
      : description

    ogImage = story.content.metatags?.og_image
  }

  const removeHeaderAndFooter = () =>
    story?.content.component === 'content_page_no_header_no_footer' ||
    story?.content.component === 'contact_page_no_header_no_footer'

  const cookiePolicyLink = linkTypeChecker(
    selectedUserType === UserType.individual
      ? footer.individual.cookie_policy
      : footer.professional.cookie_policy,
  )
  const isCookiePolicyPage = cookiePolicyLink
    ? router.asPath === `/${cookiePolicyLink.replace(`${locale}/`, '')}`
    : false

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} key="desc" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />

        {/* Only stories with exclude_from_search parameter for which it
        is not set to false, can be indexed. */}
        {(!('exclude_from_search' in story.content) && !isCookiePolicyPage) ||
        story.content.exclude_from_search ? (
          <meta name="robots" content="noindex" />
        ) : null}
        {noIndex ? <meta name="robots" content="noindex, follow" /> : null}
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Script
        id="onetrust"
        dangerouslySetInnerHTML={{
          __html: `(function(a,b,c,d){
          a='${process.env.NEXT_PUBLIC_ANALYTICS_URL}';
          b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
          a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
          })();`,
        }}
      />

      <div id="modal-root"></div>
      <Layout
        removeHeaderAndFooter={removeHeaderAndFooter()}
        translatedSlugs={story.translated_slugs}
        mainMenu={mainMenu}
        global_alert={global_alert}
        local_alert={local_alert}
        header={header}
        footer={footer}
        storyTags={story.tag_list}
      >
        {isSDPDetailPage ? (
          sdpl ? (
            <ServicePointDetailPage
              blok={story.content as ServicePointDetailPageStoryblok}
              sdpl={sdpl}
            />
          ) : null
        ) : (
          <StoryblokComponent
            blok={story.content}
            publishedAt={story.published_at}
            translatedSlugs={story.translated_slugs}
          />
        )}
      </Layout>
    </>
  )
}

export const fetchAllStories = async (
  page: number,
  results: any,
): Promise<any> => {
  const storyblokApi = getStoryblokApi()
  // console.log('SB cdn/stories fetch page ', page)

  let {data} = await storyblokApi.get('cdn/stories', {
    page: page,
    per_page: 100,
  })

  let updatedResults = results ?? {
    stories: [],
    rels: [],
    links: [],
  }

  if (updatedResults) {
    // Concatenate the data.
    updatedResults.stories = updatedResults.stories.concat(data.stories)
    updatedResults.rels = updatedResults.rels.concat(data.rels)
    updatedResults.links = updatedResults.links.concat(data.links)
  }

  if (data.stories.length === 0) {
    // Done
    return updatedResults
  }
  return await fetchAllStories(page + 1, updatedResults)
}

export async function getStaticPaths({
  locales,
}: pathTypes): Promise<GetStaticPathsResult> {
  // Refresh our datasource translations.
  await refreshTranslations(locales)

  const data = await fetchAllStories(1, null)
  // console.log('Stories found: ', data.stories.length)

  const commonStories: ISbStoryData[] = data.stories.filter(
    (stories: ISbStoryData) => stories.full_slug.includes('common'),
  )
  const individualProfessionalStories: ISbStoryData[] = data.stories.filter(
    (stories: ISbStoryData) =>
      stories.full_slug.includes(Segment.particulier) ||
      stories.full_slug.includes(Segment.professional) ||
      stories.full_slug.includes(Segment.professionnel),
  )
  const paths: GetStaticPathsResult['paths'] = []

  // Make sure the root pages are included.
  // See https://github.com/vercel/next.js/discussions/34311
  paths.push({
    params: {
      slug: [],
    },
    locale: 'nl',
  })
  paths.push({
    params: {
      slug: [],
    },
    locale: 'fr',
  })

  commonStories.forEach(story => {
    if (story.tag_list && story.tag_list.length > 0) {
      story.tag_list.forEach(tag => {
        const lTag = tag.toLowerCase()

        //generate url per slug translation which includes nl and fr
        // NL
        let nlSlug = story.default_full_slug
        if (nlSlug) {
          // Sanitize URL for NL path
          const replacement =
            lTag === Tag.individual ? Segment.particulier : lTag

          paths.push({
            params: {
              slug: nlSlug.replace('common', replacement).split('/'),
            },
            locale: 'nl',
          })
        }

        // FR
        let frSlug = story.translated_slugs?.filter(e => e.lang === 'fr')?.[0]
        if (frSlug) {
          // Sanitize URL for FR path
          const replacement =
            lTag === Tag.professional
              ? Segment.professionnel
              : lTag === Tag.individual
                ? Segment.particulier
                : lTag

          paths.push({
            params: {
              slug: frSlug.path.replace('common', replacement).split('/'),
            },
            locale: 'fr',
          })
        }
      })
    }
  })

  individualProfessionalStories.forEach(story => {
    // Sanitize URL for NL path
    let url = story.full_slug.toLowerCase()
    url = url.replace(Tag.individual, Segment.particulier)
    url = url.replace(Segment.professionnel, Segment.professional)

    paths.push({
      params: {
        slug: url.split('/'),
      },
      locale: 'nl',
    })

    //generate url per slug translation which includes nl and fr
    // NL
    let nlSlug = story.default_full_slug?.toLowerCase()
    if (nlSlug) {
      nlSlug = nlSlug.replace(Tag.individual, Segment.particulier)
      nlSlug = nlSlug.replace(Segment.professionnel, Segment.professional)
      paths.push({
        params: {
          slug: nlSlug.split('/'),
        },
        locale: 'nl',
      })
    }

    // FR
    let frSlug = story.translated_slugs
      ?.filter(e => e.lang === 'fr')?.[0]
      ?.path.toLowerCase()
    if (frSlug) {
      frSlug = frSlug.replace(Tag.individual, Segment.particulier)
      frSlug = frSlug.replace(Tag.professional, Segment.professionnel)
      paths.push({
        params: {
          slug: frSlug.split('/'),
        },
        locale: 'fr',
      })
    }
  })

  Constants.supportedLocales.forEach(async locale => {
    const sdp_paths_fuel = await SDPHelper.getInstance().generateStaticSDPPaths(
      locale,
      ServiceDeliveryPointType.fuel,
    )

    const sdp_paths_charge =
      await SDPHelper.getInstance().generateStaticSDPPaths(
        locale,
        ServiceDeliveryPointType.charge,
      )

    const sdp_paths = sdp_paths_fuel.concat(sdp_paths_charge)
    sdp_paths.forEach(path => {
      paths.push({
        params: {
          slug: path,
        },
        locale: locale,
      })
    })
  })

  return {
    paths: [...paths],
    fallback: 'blocking',
  }
}

// GetStaticPropsContext
export async function getStaticProps({
  params,
  locale,
  preview,
}: GetStaticPropsContext) {
  // Refresh our datasource translations.
  await refreshTranslations(['nl', 'fr'])

  let slugArray = params?.slug as string[]
  let slug = slugArray ? slugArray.join('/') : 'home'
  locale = locale ?? 'nl'

  let isStoryblokLivePreview =
    (slug.includes('fromStoryblok') || preview) ?? false
  if (isStoryblokLivePreview) {
    // Remove the fromStoryblok part of the path as it serves
    // no further purpose.
    slug = slug.replace('/fromStoryblok', '')
  }

  let splittedSlug = slug.split('/')

  let {
    footerIndividualSlug,
    headerIndividualSlug,
    footerProfessionalSlug,
    headerProfessionalSlug,
    mainMenuPrivateSlug,
    mainMenuProfessionalSlug,
    redirectsSlug,
  } = singletonsSlug

  let sbParams = constructSBParams(locale, isStoryblokLivePreview)

  let slugForStoryblok = slug.split('/')[0] !== 'common' ? slug : null

  let storyblokData: ISbStoryData | null = null
  const storyblokApi = getStoryblokApi()

  const redirects = await SSM.getInstance().getStoryFromCache(
    redirectsSlug,
    'default',
  )

  const privateMenu = await SSM.getInstance().getStoryFromCache(
    mainMenuPrivateSlug,
    locale,
  )
  const professionalMenu = await SSM.getInstance().getStoryFromCache(
    mainMenuProfessionalSlug,
    locale,
  )
  let mainMenu = {
    private: privateMenu.story.content,
    professional: professionalMenu.story.content,
  }

  let headerIndividual = await SSM.getInstance().getStoryFromCache(
    headerIndividualSlug,
    locale,
  )
  let headerProfessional = await SSM.getInstance().getStoryFromCache(
    headerProfessionalSlug,
    locale,
  )
  let header = {
    individual: headerIndividual.story,
    professional: headerProfessional.story,
  }

  let footerIndividual = await SSM.getInstance().getStoryFromCache(
    footerIndividualSlug,
    locale,
  )
  let footerProfessional = await SSM.getInstance().getStoryFromCache(
    footerProfessionalSlug,
    locale,
  )
  let footer = {
    individual: footerIndividual.story.content,
    professional: footerProfessional.story.content,
  }

  console.log('Slug: ', slug)
  if (slug.includes('not-found')) {
    return {
      notFound: true,
      revalidate: process.env.APP_ENV === 'prd' ? 5 * 60 : 30,
    }
  }

  //1. Check if redirection is applicable
  const redirectsEntries: RedirectEntryStoryblok[] =
    redirects.story.content.redirects_entries?.map(
      (entry: RedirectEntryStoryblok) => entry,
    ) ?? []
  const matchingRedirect: RedirectEntryStoryblok | undefined =
    redirectsEntries.find(
      redirect_entry => redirect_entry.source_url === `${slug}`,
    )
  console.log('Slug: ', slug.trim())
  // console.log('Redirect entries: ', redirectsEntries)
  // console.log('Matching redirects: ', matchingRedirect)
  // console.log(`Constructing page for ${locale}/${slug}`)
  // console.log('Is live preview: ', isStoryblokLivePreview)

  let storyLanguageFromRedirect: string | null = null

  if (redirectsEntries.length !== 0 && matchingRedirect) {
    let redirectClientType = matchingRedirect?.client_type

    if (matchingRedirect.language_target_story === 'auto') {
      storyLanguageFromRedirect = locale //matchingRedirect.source_url.split('/')[0]
    } else {
      storyLanguageFromRedirect = matchingRedirect.language_target_story
    }

    let sanitizedRedirectClientType: string = redirectClientType
    if (redirectClientType === Tag.individual) {
      sanitizedRedirectClientType = Segment.particulier
    } else if (
      redirectClientType === Tag.professional &&
      storyLanguageFromRedirect === 'fr'
    ) {
      sanitizedRedirectClientType = Segment.professionnel
    }

    let translatedFullSlug: string
    if (storyLanguageFromRedirect === 'fr') {
      translatedFullSlug = matchingRedirect.target_story.translated_slugs.find(
        (slug: TranslatedSlug) => slug.lang === storyLanguageFromRedirect,
      )?.path
    } else {
      translatedFullSlug = matchingRedirect.target_story.default_full_slug
    }

    let slugWithoutTag = translatedFullSlug.split('/').slice(1).join('/')
    const isStoryInCommonFolder = translatedFullSlug.includes('common')

    const destinationUrl = isStoryInCommonFolder
      ? `${sanitizedRedirectClientType}/${slugWithoutTag}`
      : `${translatedFullSlug}`

    storyblokData = matchingRedirect.target_story

    return {
      redirect: {
        source: `/${matchingRedirect.source_url}`,
        destination: `/${storyLanguageFromRedirect}/${destinationUrl}`,
        locale: false,
        permanent:
          matchingRedirect.redirect_type === 'permanent' ? true : false,
      },
    }
  }

  // 2. check if slug is matching requirement to generate sdp page
  if (
    slug.includes('/sdp') ||
    (isStoryblokLivePreview && slug.includes('service-point-detail-page'))
  ) {
    console.log('SDP: generating for slug: ', slug)
    const sdpData = await sdpGenerator(
      slugArray,
      isStoryblokLivePreview,
      slug,
      sbParams,
      locale,
    )

    let user_type = ''
    if (slug.includes(Segment.particulier)) {
      user_type = Segment.particulier
    } else if (
      slug.includes(Segment.professional) ||
      slug.includes(Segment.professionnel)
    ) {
      if (locale === 'fr') {
        user_type = Segment.professionnel
      } else {
        user_type = Segment.professional
      }
    }

    // Checking the validity of the slug
    const correctSDPPath = generateSDPPath(
      locale,
      user_type,
      sdpData?.props?.sdpl.detail_data!,
    )

    if (correctSDPPath && slug !== correctSDPPath) {
      return {
        redirect: {
          destination: `/${locale}/${correctSDPPath}`,
          permanent: true,
        },
      }
    }

    return sdpData
  }

  //3. Check if you try to hit home then show individual root story
  if (slug === 'home') {
    try {
      let {data} = await storyblokApi.get(`/cdn/stories/particulier`, sbParams)
      storyblokData = data.story
    } catch (err) {
      console.error('SB fetch error cdn/stories/particulier', err)
      storyblokData = null
    }
  }

  //4. SB preview check
  if (isStoryblokLivePreview && slug !== 'home') {
    // Follow the storyblok slug as it is.
    try {
      let {data} = await storyblokApi.get(`cdn/stories/${slug}`, sbParams)
      storyblokData = data.story
    } catch (err) {
      console.error('SB fetch error (slug, isStoryblokLivePreview)', err)
      storyblokData = null
    }
  }

  if (!isStoryblokLivePreview && slug !== 'home') {
    if (slugForStoryblok) {
      // 6. Proceed normally if the previous conditions haven't been met

      console.log('6. Proceeding normally: ', slugForStoryblok)
      try {
        storyblokData = await findStoryInStoryBlok(
          slugForStoryblok,
          locale,
          sbParams,
        )

        if (storyblokData && storyblokData.content.province) {
          // If the story is about an SDP overview page we need to provide
          // the SDP points.
          const fuelPoints = await fetchSDPsByProvince(
            locale,
            storyblokData.content.province,
            ServiceDeliveryPointType.fuel,
            splittedSlug[0],
          )

          const chargingPoints = await fetchSDPsByProvince(
            locale,
            storyblokData.content.province,
            ServiceDeliveryPointType.charge,
            splittedSlug[0],
          )

          storyblokData.content.sdp_fuel = fuelPoints
          storyblokData.content.sdp_charge = chargingPoints
        }
      } catch (err) {
        console.error('SB fetch error (slug, step 6)', slugForStoryblok, err)
        storyblokData = null
      }
    }
  }

  let translatedSlugs = storyblokData?.translated_slugs
  // The default Dutch language isn't in there. Manually inject it.
  translatedSlugs?.push({
    lang: 'nl',
    name: null,
    path: storyblokData?.default_full_slug ?? '',
  })

  let fixedTranslatedSlugs = translatedSlugs?.map(translatedSlug => {
    return {
      lang: translatedSlug.lang,
      name: translatedSlug.name,
      path: translatedSlug.path.replace('common', splittedSlug[0]),
    }
  })

  if (storyblokData?.translated_slugs != undefined) {
    storyblokData.translated_slugs = fixedTranslatedSlugs
  }

  //all conditions fails? data is not delivered? 404
  if (!storyblokData) {
    return {
      notFound: true,
      revalidate: process.env.APP_ENV === 'prd' ? 5 * 60 : 30,
    }
  }

  // if data comes from any condition

  let localAlertId = storyblokData.content.alert
  let localAlert = undefined
  if (localAlertId) {
    // Fetch the local alert story
    localAlert = await getLocalAlert(
      localAlertId,
      isStoryblokLivePreview,
      locale,
    )
  }
  const globalAlert = await getGlobalAlert(locale, isStoryblokLivePreview)

  // Parse the tags (Particulier / Professional) and inject them into the story and header.
  const tags = storyblokData.tag_list.map((tag: string) => tag.toLowerCase())
  storyblokData.content.tags = tags

  header.individual.content.tags = tags
  header.professional.content.tags = tags

  // Replace 'common' in links to 'particulier' or 'professional'
  if (!isStoryblokLivePreview) {
    findAndReplaceString(
      storyblokData,
      'common',
      splittedSlug[0] === 'home' ? Segment.particulier : splittedSlug[0],
    )
    findAndReplaceString(
      storyblokData,
      `fr/${Segment.professional}`,
      `fr/${Segment.professionnel}`,
    )
    findAndReplaceString(
      storyblokData,
      `nl/${Segment.professionnel}`,
      `nl/${Segment.professional}`,
    )
    findAndReplaceString(
      footer,
      'common',
      splittedSlug[0] === 'home' ? Segment.particulier : splittedSlug[0],
    )
    findAndReplaceString(
      header,
      'common',
      splittedSlug[0] === 'home' ? Segment.particulier : splittedSlug[0],
    )
    findAndReplaceString(
      mainMenu,
      'common',
      splittedSlug[0] === 'home' ? Segment.particulier : splittedSlug[0],
    )
    findAndReplaceString(
      globalAlert,
      'common',
      splittedSlug[0] === 'home' ? Segment.particulier : splittedSlug[0],
    )
    findAndReplaceString(
      localAlert,
      'common',
      splittedSlug[0] === 'home' ? Segment.particulier : splittedSlug[0],
    )
  }

  return {
    props: {
      isSDPDetailPage: false,
      mainMenu: mainMenu,
      footer: footer,
      story: storyblokData,
      key: storyblokData ? storyblokData.id : false,
      global_alert: globalAlert ?? false,
      local_alert: localAlert ?? false,
      header: header,
      locale: locale,
      ...(await serverSideTranslations(locale ?? 'nl', ['common'])),
    },
    revalidate:
      process.env.APP_ENV === 'prd'
        ? slug.includes('/sdp') || storyblokData.content.province
          ? 60 * 60
          : 5 * 60
        : 30, // prd
  }
}
