import React from 'react'
import {
  AlgoliaNoResultsStoryblok,
  FaqCtaStoryblok,
  SearchBarStoryblok,
  SearchInputValidationMessageStoryblok,
} from 'types/storyblok-types'
import AlgoliaWindowResults from './AlgoliaWindowResults'

import Image from 'next/image'
import algoliasearch from 'algoliasearch/lite'
import {i18n} from 'next-i18next'
import {ConsequenceParams} from '@algolia/client-search'
import classNames from 'classnames'
import {Configure, InstantSearch, SearchBox} from 'react-instantsearch'
import {useRouter} from 'next/router'
import {Segment, Tag} from 'util/slugHelpers/segment'
import _ from 'lodash'

interface HeaderSearchBarContainerProps {
  searchBar: SearchBarStoryblok
  setIsSearchClicked: React.Dispatch<React.SetStateAction<boolean>>
  noResults: AlgoliaNoResultsStoryblok
  faqCta: FaqCtaStoryblok
  searchValidationMessage: SearchInputValidationMessageStoryblok
  logoFileName?: string
  logoAlt?: string
}

export const defineIndexByLang = (lang: string) => {
  if (lang && lang === 'nl') {
    return process.env.NEXT_PUBLIC_INDEX_NL!
  }

  if (lang && lang === 'fr') {
    return process.env.NEXT_PUBLIC_INDEX_FR!
  }
  return process.env.NEXT_PUBLIC_INDEX_NL!
}

function HeaderSearchBarContainer({
  searchBar,
  noResults,
  faqCta,
  setIsSearchClicked,
  searchValidationMessage,
  logoFileName,
  logoAlt,
}: HeaderSearchBarContainerProps) {
  const algoliaClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY!,
  )

  // Preventing fetching data on page load
  const searchClient = {
    ...algoliaClient,
    search(requests: any) {
      if (
        requests.every(({params}: {params: ConsequenceParams}) => !params.query)
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        })
      }

      return algoliaClient.search(requests)
    },
  }

  const inputClass = classNames(
    ' [&_input]:py-2.5 appearance-none [&_input]:border-0 [&_input]:w-full [&_input]:pl-4 ',
  )
  const resetButtonClass = classNames(
    `[&_button[type='reset']]:absolute [&_button[type='reset']]:right-1 [&_button[type='reset']]:h-full`,
  )

  const lang = i18n?.language!

  const {asPath} = useRouter()

  let tagFromAsPath = null

  if (
    asPath.split('/')[1] === Segment.professional ||
    asPath.split('/')[1] === Segment.professionnel
  ) {
    tagFromAsPath = _.capitalize(Tag.professional)
  } else {
    tagFromAsPath = _.capitalize(Tag.individual)
  }

  const isSafariAndIos = () => {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
    return isSafari && iOS
  }

  return (
    <InstantSearch
      // @ts-ignore No idea what it expect from me
      searchClient={searchClient}
      indexName={defineIndexByLang(lang)}
      insights
    >
      <Configure
        hitsPerPage={5}
        filters={`story.tag_list:${tagFromAsPath}`}
        optionalFilters={[
          'story.content.search_categories.content.title: Offre',
          'story.content.search_categories.content.title: Offer',
          'story.content.search_categories.content.title: Aanbod',
        ]}
      />
      <>
        <div className="flex w-full justify-center border-dats-s10 pb-3 pt-[10px] lg:border-b">
          {logoFileName ? (
            <a
              href="/"
              className="absolute left-0 top-[14px] ml-[100px] hidden h-8 w-28 lg:block"
            >
              <Image
                src={logoFileName}
                alt={logoAlt ?? ''}
                fill
                className="object-cover"
                // fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </a>
          ) : null}
          <div className="relative w-full max-w-lg pr-8">
            <SearchBox
              searchAsYouType
              placeholder={searchBar.placeholder}
              submitIconComponent={() => null}
              resetIconComponent={() => null}
              autoFocus
              queryHook={(query, search) => {
                if (query && query.length >= 3) {
                  search(query)
                } else {
                  search('')
                }
              }}
              className={`flex size-full rounded-md border border-dats-s9 bg-white font-body ${
                isSafariAndIos() ? 'text-[16px]' : 'text-body'
              } font-medium text-dats-s5 outline-none focus:bg-white focus:text-gray-900 ${inputClass} ${resetButtonClass} overflow-hidden [&_form]:flex [&_form]:w-full`}
            />
            <button
              type="button"
              onClick={() => setIsSearchClicked(false)}
              className="absolute -right-3 -top-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="50"
                viewBox="0 0 49 51"
                fill="none"
              >
                <path
                  d="M15.7059 15.8794C16.2415 15.3215 17.1098 15.3215 17.6454 15.8794L24.9042 23.4407L32.1631 15.8794C32.6986 15.3215 33.567 15.3215 34.1025 15.8794C34.6381 16.4373 34.6381 17.3418 34.1025 17.8997L26.8437 25.461L34.1025 33.0223C34.6381 33.5801 34.6381 34.4847 34.1025 35.0425C33.567 35.6004 32.6986 35.6004 32.1631 35.0425L24.9042 27.4812L17.6454 35.0425C17.1098 35.6004 16.2415 35.6004 15.7059 35.0425C15.1703 34.4847 15.1703 33.5801 15.7059 33.0223L22.9647 25.461L15.7059 17.8997C15.1703 17.3418 15.1703 16.4373 15.7059 15.8794Z"
                  fill="#005E75"
                />
              </svg>
            </button>
          </div>
        </div>
      </>
      <div className="relative flex w-full justify-center">
        <div className="md:min-w-lg w-full md:max-w-lg">
          <div className="relative mt-2 flex h-full flex-col items-center">
            <AlgoliaWindowResults
              searchValidationMessage={searchValidationMessage}
              noResults={noResults}
              faqCta={faqCta}
            />
          </div>
        </div>
      </div>
    </InstantSearch>
  )
}

export default HeaderSearchBarContainer
