import Image from 'next/image'
import React from 'react'
import {
  ButtonStoryblok,
  CtaBlockStoryblok,
  HeaderStoryblok,
} from 'types/storyblok-types'
import {HeaderBlock, HeaderType} from './Header'
import {renderRichText, storyblokEditable} from '@storyblok/react'
import ButtonBlock from 'components/shared/ButtonBlock'
import {getImageResolution} from 'util/getImageResolution'
import classNames from 'classnames'
import {TextBlock} from './Text'
import linkTypeChecker from 'util/linkTypeChecker'
type CtaContainerTypeProps = {
  blok: CtaBlockStoryblok
  noLeftTextMargin?: boolean
}
type CtaBlockTypeProps = {
  image_url: CtaBlockStoryblok['image']['filename']
  text: string
  image_column: CtaBlockStoryblok['image_column']
  title: HeaderStoryblok['title']
  button_label: ButtonStoryblok['button_label']
  button_color: ButtonStoryblok['button_color']
  blok: CtaBlockStoryblok
  image_alt?: string
  button_url: string
  button_url_target?: string
  no_left_text_margin?: boolean
}

export const CtaBlock = ({
  image_alt,
  image_column,
  image_url,
  title,
  button_color = 'secondary',
  button_label = 'Cta Button',
  text,
  blok,
  button_url = '',
  button_url_target,
  no_left_text_margin = false,
}: CtaBlockTypeProps) => {
  const imageResolution = getImageResolution(image_url)

  const contentContainer = classNames('md:w-6/12 py-9 md:py-16 ', {
    'px-10': !no_left_text_margin,
    'pr-10': image_column === 'right' && no_left_text_margin,
    'pl-10': image_column === 'left' && no_left_text_margin,
  })

  const ctaClassWrapper = classNames(
    'flex overflow-hidden rounded-dats bg-dats-s10',
    {
      'flex-col-reverse md:flex-row-reverse': image_column === 'right',
      'flex-col md:flex-row': image_column === 'left',
    },
  )

  return (
    <div className={ctaClassWrapper} {...storyblokEditable(blok)}>
      <div className="relative md:w-1/2">
        <Image
          src={image_url}
          alt={image_alt ? image_alt : ''}
          fill
          className="hidden object-cover md:block"
        />
        <Image
          src={image_url}
          alt={image_alt ? image_alt : ''}
          width={imageResolution.width}
          height={imageResolution.height}
          className="w-full object-cover md:hidden"
        />
      </div>
      <div className={contentContainer}>
        <HeaderBlock title={title} type={HeaderType.h3} />
        <TextBlock innerHtml={text} />
        {/* storyblok has problem in preview mode at block creation (button is a separate nested block inside of CTA)*/}
        <ButtonBlock
          title={button_label}
          type="button"
          styling={button_color}
          className="mt-6"
          cta_url={button_url}
          cta_target={button_url_target}
        />
      </div>
    </div>
  )
}

const CtaContainer = ({blok, noLeftTextMargin}: CtaContainerTypeProps) => {
  return (
    <CtaBlock
      title={blok.title}
      blok={blok}
      button_label={blok.button[0] ? blok.button[0].button_label : ''}
      button_color={blok.button[0] ? blok.button[0].button_color : 'primary'}
      image_url={blok.image.filename}
      image_alt={blok.image.alt}
      image_column={blok.image_column}
      text={renderRichText(blok.text) ?? ''}
      button_url={blok.button[0] ? linkTypeChecker(blok.button[0].link) : null}
      button_url_target={blok.button[0]?.link.target}
      no_left_text_margin={noLeftTextMargin ?? false}
    />
  )
}

export default CtaContainer
