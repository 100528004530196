import {storyblokEditable} from '@storyblok/react'
import React, {useEffect, useState} from 'react'
import {AlertStoryblok} from 'types/storyblok-types'
import classNames from 'classnames'
import Image from 'next/image'
import linkTypeChecker from 'util/linkTypeChecker'
import Link from 'next/link'

interface AlertProps {
  id: string
  title: string
  image_url?: string
  has_cta: boolean
  cta_url?: string
  type: AlertStoryblok['type']
  displayClose: boolean
  blok: AlertStoryblok
}

export const Alert = ({
  id,
  title,
  image_url,
  has_cta,
  cta_url,
  type,
  displayClose,
  blok,
}: AlertProps) => {
  const [isHidden, setIsHidden] = useState(false)
  const hideBanner = () => {
    // Mark the banner as hidden.
    localStorage.setItem(`alert_${id}`, 'hidden')
    setIsHidden(true)
  }

  const closeIconPath = () => {
    switch (type) {
      case 'informative':
        return '/images/alert/informative_close.svg'
      case 'warning':
        return '/images/alert/warning_close.svg'
      case 'error':
        return '/images/alert/error_close.svg'
    }
  }

  const mainIconPath = () => {
    switch (type) {
      case 'informative':
        return ''
      case 'warning':
        return '/images/alert/warning.svg'
      case 'error':
        return '/images/alert/error.svg'
    }
  }

  const containerClassnames = classNames(
    'h-auto flex items-center justify-center md:min-h-[50px] py-2 md:py-0 w-full lg:px-[100px] px-6',
    {
      'bg-dats-alert-informative': type === 'informative',
      'bg-dats-alert-warning': type === 'warning',
      'bg-dats-alert-error': type === 'error',
    },
  )

  const contentClasses = 'flex flex-1 items-center justify-start no-underline'

  const content = (
    <>
      <p className="ml-2 font-sans text-sm font-semibold text-dats-s5">
        {title}
      </p>

      {has_cta ? (
        <Image
          src="/images/alert/alert_chevron.svg"
          alt={''}
          width={17}
          height={17}
          className="mx-2 hidden pt-0.5 md:block"
        />
      ) : null}
    </>
  )

  return (
    <>
      {!isHidden ? (
        <div className={containerClassnames} {...storyblokEditable(blok)}>
          <div className="w-8">
            {type === 'informative' && image_url ? (
              <Image src={image_url} alt={''} width={20} height={20} />
            ) : null}

            {type === 'warning' || type === 'error' ? (
              <Image src={mainIconPath()} alt={''} width={20} height={20} />
            ) : null}
          </div>
          {has_cta ? (
            <Link href={cta_url ?? ''} className={contentClasses}>
              {content}
            </Link>
          ) : (
            <div className={contentClasses}>{content}</div>
          )}

          <div onClick={hideBanner} className="cursor-pointer py-3 pl-4">
            {displayClose ? (
              <Image src={closeIconPath()} alt={''} width={14} height={14} />
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

const AlertContainer = ({blok}: {blok: AlertStoryblok}) => {
  const [displayBanner, setDisplayBanner] = useState<boolean>(false)

  useEffect(() => {
    const value = localStorage.getItem(`alert_${blok._uid}`)
    setDisplayBanner(value === null)
  }, [blok._uid])

  return (
    <>
      {displayBanner ? (
        <Alert
          id={blok._uid}
          title={blok.title}
          image_url={blok.icon?.filename}
          has_cta={blok.has_cta ?? false}
          cta_url={linkTypeChecker(blok.cta_url)}
          type={blok.type}
          displayClose={blok.displayCloseButton ?? false}
          blok={blok}
        />
      ) : null}
    </>
  )
}
export default AlertContainer
