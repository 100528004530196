import React from 'react'
import Image from 'next/image'
import {TextBlock} from './Text'
import {getImageResolution} from 'util/getImageResolution'
import {MultilinkStoryblok, TextImageStoryblok} from 'types/storyblok-types'
import {renderRichText, storyblokEditable} from '@storyblok/react'
import {HeaderBlock, HeaderType} from './Header'
import classNames from 'classnames'

import ButtonBlock from 'components/shared/ButtonBlock'
import Link from 'next/link'
import linkTypeChecker from 'util/linkTypeChecker'

type TextImageProps = {
  title?: string
  richText: string
  imageUrl?: string
  imageAlt?: string
  imageLink?: MultilinkStoryblok | undefined
  imageColumn: TextImageStoryblok['image_column']
  roundedImage: TextImageStoryblok['rounded_image']
  buttonLabel: string | undefined
  buttonColor: 'primary' | 'secondary' | ''
  buttonLink: MultilinkStoryblok | undefined
  blok?: TextImageStoryblok
}

export const TextImageBlock = ({
  title,
  richText,
  imageUrl,
  imageAlt,
  imageLink,
  imageColumn,
  roundedImage,
  buttonLabel,
  buttonColor,
  buttonLink,
  blok,
}: TextImageProps) => {
  const {width, height} = getImageResolution(imageUrl ?? '')
  const imageOnLeft = imageColumn === 'left'

  const constructButtonBlok = () => {
    if (!buttonLabel || !buttonLink || buttonColor.length === 0) {
      return null
    }

    const sanitizedButtonColor =
      buttonColor === 'primary' ? 'primary' : 'secondary'
    return (
      <ButtonBlock
        title={buttonLabel}
        type="button"
        styling={sanitizedButtonColor}
        className="mt-6"
        cta_url={linkTypeChecker(buttonLink)}
        cta_target={buttonLink.target}
      />
    )
  }
  return (
    <div
      className="grid grid-cols-1 gap-8 lg:grid-cols-2"
      {...storyblokEditable(blok!)}
    >
      {!imageOnLeft ? (
        <div className="flex flex-col items-start justify-start pb-8 lg:pb-0">
          <HeaderBlock title={title ?? ''} type={HeaderType.h2} />
          <TextBlock innerHtml={richText} />
          {constructButtonBlok()}
        </div>
      ) : null}

      <div className="mx-auto flex w-full items-center justify-center rounded-md lg:hidden">
        {imageUrl ? (
          imageLink && imageLink.cached_url.length > 0 ? (
            <Link href={imageLink.cached_url} target={imageLink.target}>
              <Image
                src={imageUrl}
                alt={imageAlt ?? ''}
                height={height}
                width={width}
                className={classNames({'rounded-dats-xl': roundedImage})}
              />
            </Link>
          ) : (
            <Image
              src={imageUrl}
              alt={imageAlt ?? ''}
              height={height}
              width={width}
              className={classNames({'rounded-dats-xl': roundedImage})}
            />
          )
        ) : null}
      </div>
      <div className="relative hidden aspect-video w-full rounded-md lg:block">
        {imageUrl ? (
          imageLink && imageLink.cached_url.length > 0 ? (
            <Link href={imageLink.cached_url} target={imageLink.target}>
              <Image
                src={imageUrl}
                alt={imageAlt ?? ''}
                fill={true}
                className={classNames('object-cover', {
                  'rounded-dats-xl': roundedImage,
                })}
              />
            </Link>
          ) : (
            <Image
              src={imageUrl}
              alt={imageAlt ?? ''}
              fill={true}
              className={classNames('object-cover', {
                'rounded-dats-xl': roundedImage,
              })}
            />
          )
        ) : null}
      </div>

      {imageOnLeft ? (
        <div className="flex flex-col items-start justify-start pb-8 lg:pb-0">
          <HeaderBlock title={title ?? ''} type={HeaderType.h2} />
          <TextBlock innerHtml={richText} />
          {constructButtonBlok()}
        </div>
      ) : null}
    </div>
  )
}

type TextImageContainerProps = {
  blok: TextImageStoryblok
}
const TextImageContainer = ({blok}: TextImageContainerProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <TextImageBlock
        title={blok.title ?? ''}
        richText={renderRichText(blok.richtext) ?? ''}
        imageUrl={blok.image.filename}
        imageAlt={blok.image.alt}
        imageLink={blok.image_link}
        imageColumn={blok.image_column ?? 'right'}
        roundedImage={blok.rounded_image}
        buttonLabel={blok.button_label}
        buttonColor={blok.button_color ?? 'primary'}
        buttonLink={blok.button_link}
        blok={blok}
      />
    </div>
  )
}
export default TextImageContainer
