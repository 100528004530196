import React, {useEffect, useState} from 'react'
import Image from 'next/image'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
  faBars,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import Logo from '../../../public/images/mobile-logo.svg'
import {
  CustomerTypeLinkStoryblok,
  MainMenuStoryblok,
  SubMenuItemStoryblok,
  TopHeaderIndividualStoryblok,
  TopHeaderProfessionalStoryblok,
} from 'types/storyblok-types'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'
import CustomerTypeToggle from './CustomerTypeToggle'
import {StoryTag, TranslatedSlug} from 'types/types'
import classNames from 'classnames'
import LanguageToggle from 'components/LanguageToggle'
import AlgoliaContainer from './AlgoliaSearch/AlgoliaContainer'
import {useSessionContext} from 'context/SessionContextProvider'
import HeaderLoginProfessional from './HeaderLoginProfessional'
import {useRouter} from 'next/router'
import linkTypeChecker from 'util/linkTypeChecker'
import {Segment} from 'util/slugHelpers/segment'

type MobileMenuPropsType = {
  customerTypeData: CustomerTypeLinkStoryblok[] | undefined
  storyTags: StoryTag[]
  translatedSlugs?: TranslatedSlug[]
  mainMenu: MainMenuStoryblok
  header: TopHeaderProfessionalStoryblok | TopHeaderIndividualStoryblok
}

const MobileMenu = ({
  mainMenu,
  header,
  customerTypeData,
  storyTags,
  translatedSlugs,
}: MobileMenuPropsType) => {
  const router = useRouter()
  const sessionContext = useSessionContext()

  const [isOpen, setIsOpen] = useState(false)

  const linkForLogo = () => {
    const locale = router.locale ?? 'nl'
    let segment = router.asPath.split('/')[1]
    if (segment) {
      if (locale === 'nl' && segment === Segment.professionnel) {
        segment = Segment.professional
      } else if (locale === 'fr' && segment === Segment.professional) {
        segment = Segment.professionnel
      }
      if (
        segment === Segment.professional ||
        segment === Segment.professionnel ||
        segment === Segment.particulier
      ) {
        return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${locale}/${segment}`
      }
    }
    return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${locale}/${Segment.particulier}`
  }

  const [secondaryMenuData, setSecondaryMenuData] = useState<
    SubMenuItemStoryblok[] | undefined | null
  >(null)

  const [isMobileSearchClicked, setIsMobileSearchClicked] =
    useState<boolean>(false)

  // @ts-ignore
  const {t} = useTranslation()

  const [scrollValue, setScrollValue] = useState<number>(0)
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down')

  const mobilePrimaryMenuItems = mainMenu.main_menu_item?.filter(
    menuItem => menuItem.primary_mobile_item,
  )
  const mobileRestMenuItems = mainMenu.main_menu_item?.filter(
    menuItem => !menuItem.primary_mobile_item,
  )

  const sanitizeUrl = (url: string) => {
    if (router.asPath?.includes('xtra/login')) {
      // Storyblok returns default language URLs (Dutch) without
      // the locale and without a prefix slash. But for FR it does
      // include the locale with a prefix slash.
      // The prefix slash is required on the xtra/login page to make
      // sure all links remain relative to the root of the page.
      // Therefore we add it manually if needed.
      if (!url.startsWith('/') && !url.startsWith('http')) {
        return `/${url}`
      }
    }
    return url
  }

  useEffect(() => {
    if (!router.isReady) return
    const addXtraListener = () => {
      if (typeof window !== 'undefined' && window.XTRA) {
        window.XTRA.form.render()
      }
    }

    setTimeout(function () {
      addXtraListener()
    }, 300)
  }, [router.locale, isOpen, router.isReady])

  useEffect(() => {
    if (!router.isReady) return

    if (router.asPath.includes('#custModify9mhc')) {
      // Redirect to profile page.
      window.location.href = `https://profile.dats24.be/${
        router.locale ?? 'nl'
      }/`
    }
  }, [router.asPath, router.isReady, router.locale])

  useEffect(() => {
    if (!isMobileSearchClicked) {
      window.addEventListener('scroll', handleScrollDirection)
    }

    return () => {
      window.removeEventListener('scroll', handleScrollDirection)
    }
  }, [isMobileSearchClicked])

  function handleScrollDirection() {
    // Do not setState on scroll when mobileSearchIsOpen it makes algolia reload
    setScrollValue(prev => {
      let previousValue = prev ? prev : 0
      if (previousValue < window.scrollY) {
        setScrollDirection('down')
      } else if (previousValue > window.scrollY) {
        setScrollDirection('up')
      }
      return window.scrollY
    })
  }

  const heightOfTopHeader = 48

  const mobileHeaderClass = classNames(
    'z-[60] flex w-full items-center justify-between border-b border-x-dats-border-gray bg-white px-5 transition-opacity',
    {'fixed top-0 pl-4 pr-0 w-full left-0 opacity-100': isOpen},
    {
      'opacity-0':
        scrollDirection === 'down' &&
        scrollValue > heightOfTopHeader &&
        !isMobileSearchClicked,
    },
    {
      'fixed top-0 pl-4 pr-0 w-full left-0 opacity-100':
        scrollDirection === 'up' && scrollValue > heightOfTopHeader,
    },
  )

  return (
    <>
      {!isOpen ? (
        <div className="flex w-full flex-col border-b border-dats-s9 md:hidden">
          <div className="border-b border-dats-s10 px-8">
            {customerTypeData && customerTypeData.length > 0 ? (
              <CustomerTypeToggle
                items={customerTypeData}
                storyTags={storyTags}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      <div className={mobileHeaderClass}>
        {!secondaryMenuData ? (
          <Link href={linkForLogo()}>
            <Image src={Logo} alt="dats24 logo" />
          </Link>
        ) : (
          <>
            <button
              className="flex items-center font-semibold text-dats-s3"
              onClick={() => setSecondaryMenuData(null)}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className="mr-2 w-3 text-base"
              />
              <span>{t('go_back')}</span>
            </button>
          </>
        )}
        <div className="flex items-center justify-center">
          <FontAwesomeIcon
            icon={faSearch}
            width={21}
            height={21}
            onClick={() => setIsMobileSearchClicked(true)}
            className="text-xl text-dats-s3"
          />
          {isMobileSearchClicked && header.search_bar ? (
            <div className="fixed left-0 top-0 z-[99999] h-screen w-full overflow-y-auto bg-white md:flex md:flex-row md:justify-center">
              <div className="border-b border-dats-s10">
                {customerTypeData && customerTypeData.length > 0 ? (
                  <CustomerTypeToggle
                    items={customerTypeData}
                    storyTags={storyTags}
                  />
                ) : null}
              </div>
              <div className="px-5">
                <AlgoliaContainer
                  searchBar={header.search_bar[0]}
                  noResults={header.algolia_no_results[0]}
                  setIsSearchClicked={setIsMobileSearchClicked}
                  faqCta={header.faq_block[0]}
                  searchValidationMessage={header.search_validation_message[0]}
                />
              </div>
            </div>
          ) : null}
          <button
            className="flex size-14 items-center justify-center"
            onClick={() => (
              setIsOpen(prevState => !prevState), setSecondaryMenuData(null)
            )}
            aria-label="toggle menu"
          >
            {!isOpen ? (
              <FontAwesomeIcon
                icon={faBars}
                width={24}
                height={24}
                className="size-6 text-3xl text-dats-s3"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="51"
                viewBox="0 0 49 51"
                fill="none"
              >
                <path
                  d="M15.7059 15.8794C16.2415 15.3215 17.1098 15.3215 17.6454 15.8794L24.9042 23.4407L32.1631 15.8794C32.6986 15.3215 33.567 15.3215 34.1025 15.8794C34.6381 16.4373 34.6381 17.3418 34.1025 17.8997L26.8437 25.461L34.1025 33.0223C34.6381 33.5801 34.6381 34.4847 34.1025 35.0425C33.567 35.6004 32.6986 35.6004 32.1631 35.0425L24.9042 27.4812L17.6454 35.0425C17.1098 35.6004 16.2415 35.6004 15.7059 35.0425C15.1703 34.4847 15.1703 33.5801 15.7059 33.0223L22.9647 25.461L15.7059 17.8997C15.1703 17.3418 15.1703 16.4373 15.7059 15.8794Z"
                  fill="#005E75"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      {isOpen ? (
        <div
          className={`px-15 overflow-y-auto ${
            scrollValue > heightOfTopHeader ? 'fixed' : 'absolute'
          } left-0 top-0 z-50 size-full bg-dats-s10 pt-14`}
        >
          {!secondaryMenuData ? (
            <>
              <ul className="mx-5 mt-3 rounded-dats bg-white font-semibold [&_a]:text-base">
                {mobilePrimaryMenuItems?.map(menuItem => (
                  <li
                    key={menuItem._uid}
                    className="flex items-center justify-between border-t border-t-dats-border-gray p-3 text-dats-s3 first:border-t-0"
                  >
                    <Link
                      className="block w-auto no-underline"
                      href={sanitizeUrl(linkTypeChecker(menuItem.url))}
                      target={menuItem.url?.target}
                    >
                      {menuItem.label}
                    </Link>
                    {menuItem.sub_menu_item &&
                    menuItem.sub_menu_item.length > 0 ? (
                      <button
                        className="flex h-full grow justify-end"
                        onClick={() =>
                          setSecondaryMenuData(menuItem.sub_menu_item)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="lg"
                          className="h-5"
                        />
                      </button>
                    ) : null}
                  </li>
                ))}
              </ul>
              <ul className="mx-5 mt-6 rounded-dats bg-white font-semibold md:mt-0 [&_a]:text-base">
                {mobileRestMenuItems?.map(menuItem => (
                  <li
                    key={menuItem._uid}
                    className="border-t border-t-dats-border-gray text-dats-s3 first:border-t-0"
                  >
                    <Link
                      className="block p-3 no-underline"
                      href={sanitizeUrl(linkTypeChecker(menuItem.url))}
                      target={menuItem.url?.target}
                    >
                      {menuItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="mt-4 flex items-center justify-between px-5">
                {!sessionContext.isIndividual() ? (
                  <HeaderLoginProfessional
                    cta_url={sanitizeUrl(
                      linkTypeChecker(header.professional_login_link),
                    )}
                  />
                ) : (
                  <div className="h-[44px] w-[200px]">
                    {/* @ts-ignore  */}
                    <xtra-header-menu exclude-items="custModify,securityMgmt,custIdentifiers,benefits,fuelFunction,payFuncMgmt,privacy,subscriptionsModify,community,custPrefs,memoryLists">
                      {/* @ts-ignore  */}
                      <xtra-item
                        href={`/wps/portal/datscustomer/${
                          router.locale ?? 'nl'
                        }/dats24/xtra/dashboard${router.locale ?? 'nl'}`}
                        class="ico-plus"
                        label="Profiel"
                      >
                        {/* @ts-ignore  */}
                      </xtra-item>
                      {/* @ts-ignore  */}
                    </xtra-header-menu>
                  </div>
                )}

                <LanguageToggle translatedSlugs={translatedSlugs} />
              </div>
            </>
          ) : (
            <div className="h-full bg-white">
              {secondaryMenuData.map(secondaryMenuItem => (
                <Link
                  href={sanitizeUrl(linkTypeChecker(secondaryMenuItem.url))}
                  className="no-underline"
                  key={secondaryMenuItem._uid}
                  target={secondaryMenuItem.url?.target}
                >
                  <button className="group flex w-full flex-col items-start border-b border-b-dats-border-gray p-5 focus:bg-dats-border-s2/50">
                    <span className="block text-base font-semibold text-dats-s3 group-focus:text-dats-s5">
                      {secondaryMenuItem.label}
                    </span>
                    {secondaryMenuItem.description ? (
                      <p className="text-left text-sm font-normal text-dats-s6 group-focus:text-dats-s5">
                        {secondaryMenuItem.description}
                      </p>
                    ) : null}
                  </button>
                </Link>
              ))}
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default MobileMenu
